import React from 'react';
import { graphql } from 'gatsby';
import Content from 'components/_ui/Content';
import styled from "@emotion/styled";
import dimensions from "styles/dimensions";
import { Inner, Wrap } from "styles/structure";
import Layout from 'components/Layout';
import SEO from "components/SEO";
import AboutHeader from 'components/_page/about/Header';
import SpecialtyContent from "components/_page/specialty/Content";
import SpecialtyServices from "components/_page/specialty/Services";
import CaseStudyCard from "components/_card/CaseStudy";
import SectionHeading from "components/_ui/SectionHeading";
import InsightsGrid from "components/_grid/Insights";
import InsightCard from "components/_card/Insight";
import GetInTouch from "components/GetInTouch";
import Related from 'components/Related';

const SpecialtyStudies = styled.section`
  margin-top: 14.4rem;
`;

const InsightsContainer = styled.section`
  background-color: #000;
  color: #fff;
  padding: 8rem 0;
  
  header {
    & + * {
      margin-top: 4.8rem;
    }
    
    div {
      margin-top: 1.6rem;
    }
  }
  
  p {
    max-width: 74rem;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    & + * {
      margin-top: 8rem;
    }
    
    header {
      & + * {
        margin-top: 6.4rem;
      }
    }
  }
`;

const RenderBody = ({ specialty, site }) => (
  <>
    <AboutHeader
      title={specialty.name ? specialty.name[0].text : 'Our Specialty'}
      heading={specialty.introduction_heading}
      content={specialty.introduction_content}
      hero={true}
      parallax={[-100, 60]}
      variant='specialty'
    />

    <SpecialtyServices services={specialty.services}/>

    <SpecialtyContent
      title={`${specialty.name ? specialty.name[0].text : 'Our Specialty'} in Action`}
      heading={specialty.action_heading}
      content={specialty.action_content}
    />

    <SpecialtyStudies>
      {specialty.featured_studies.map((study, i) => {
        if (i % 2 === 0) {
          return (
            <CaseStudyCard
              key={i}
              align="left"
              uid={study.study._meta.uid}
              image={study.study.feature_image}
              name={study.study.client_name}
              area={study.study.area}
              tagline={study.study.tagline}
            />
          );
        } else {
          return (
            <CaseStudyCard
              key={i}
              align="right"
              uid={study.study._meta.uid}
              image={study.study.feature_image}
              name={study.study.client_name}
              area={study.study.area}
              tagline={study.study.tagline}
            />
          );
        }
      })}
    </SpecialtyStudies>

    <InsightsContainer>
      <Inner>
        <header>
          <Wrap>
            <SectionHeading>Related Insights</SectionHeading>

            <div>
              <Content content={specialty.featured_insights_content}/>
            </div>
          </Wrap>
        </header>

        <InsightsGrid layout="full">
          {specialty.featured_insights.map((insight, i) => {
            if (insight.insight && insight.insight._meta.uid) {
              return (
                <li key={i}>
                  <InsightCard
                    destination={`/insight/${insight.insight._meta.uid}`}
                    image={insight.insight.feature_image}
                    title={insight.insight.title}
                    category={insight.insight.category}
                  />
                </li>
              )
            }

            return null
          })}
        </InsightsGrid>
      </Inner>
    </InsightsContainer>

    <GetInTouch
      title={specialty.get_in_touch_heading || site.get_in_touch_heading || 'Everything starts with a conversation.'}
      signpost="View more"
    />

    <Related
      heading='Learn more'
      type='specialty'
      cards={specialty.more_specialties}
    />
  </>
);

export default ({ data }) => {
  const specialty = data.prismic.specialtyByUID;
  const site = data.prismic.allSite_settingss;

  if (!specialty) return null;

  return (
    <Layout>
      <SEO
        title={specialty.social_title || (specialty.name && specialty.name[0].text) || 'Specialty'}
        description={specialty.social_description ? specialty.social_description : null}
        image={specialty.social_image ? specialty.social_image.url : null}
      />

      <RenderBody specialty={specialty} site={site} />
    </Layout>
  );
};

export const query = graphql`
  query SpecialtyQuery($uid: String!) {
    prismic {
      specialtyByUID: specialty(uid: $uid, lang: "en-us") {
        name
        short_description
        feature_image
        introduction_heading
        introduction_content
        services {
          content
          image
          title
        }
        action_heading
        action_content
        featured_studies {
          study {
            ... on PRISMIC_Case_study {
              client_name
              tagline
              feature_image
              area {
                ... on PRISMIC_Area {
                  name
                }
              }
              _meta {
                uid
              }
            }
          }
        }
        featured_insights_content
        featured_insights {
          insight {
            ... on PRISMIC_Insight {
              title
              feature_image
              _meta {
                uid
              }
              category {
                ... on PRISMIC_Category {
                  name
                }
              }
            }
          }
        }
        more_specialties {
          specialty {
            ... on PRISMIC_Specialty {
              _meta {
                  uid
              }
              name
              short_description
              feature_image
            }
          }
        }
        get_in_touch_heading
        social_title
        social_description
        social_image
      }
      allSite_settingss {
        edges {
          node {
            get_in_touch_heading
          }
        }
      }
    }
  }
`;
