import React, { Component } from 'react';
import styled from "@emotion/styled";
import dimensions from "styles/dimensions";

const GridContainer = styled.ul`
  width: 100%;
  position: relative;
  
  li {
    display: block;
    
    & + * {
      margin-top: 0.8rem;
    }
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 2.5vw);
    left: -1.25vw;
    
    li {
      flex: 1 1 calc(50% - 2.5vw);
      margin-left: 1.25vw;
      margin-right: 1.25vw;
    
      & + * {
        margin-top: 0;
      }
      
      &:nth-of-type(n+3) {
        margin-top: 4rem;
      }
    }
  }
  
  @media(min-width: ${dimensions.desktopTransitionUp}px) {
    display: flex;
    width: calc(100% + 2.5vw);
    left: -1.25vw;
    
    li {
      flex: 0 1 calc(33.3334% - 2.5vw);
      margin-left: 1.25vw;
      margin-right: 1.25vw;
    
      & + * {
        margin-top: 0;
      }
      
      &:nth-of-type(n+3) {
        margin-top: 0;
      }
      
      &:nth-of-type(n+4) {
        margin-top: 4rem;
      }
    }
  }
  
  @media(min-width: ${dimensions.desktopUp}px) {
    width: calc(100% + 3.6rem);
    left: -1.8rem;
      
    &.is-full {
      li {
        &:first-child {
          flex: 0 1 calc(66.6667% - 3.6rem);
        }
        
        &:nth-of-type(3) {
          margin-top: 4rem;
        }
      } 
    }
      
    li {
      flex: 0 1 calc(33.3334% - 3.6rem);
      margin-left: 1.8rem;
      margin-right: 1.8rem;
    }
  }
`

class InsightsGrid extends Component {
  render() {
    return (
      <GridContainer className={this.props.layout && `is-${this.props.layout}`}>
        {this.props.children}
      </GridContainer>
    );
  }
}

export default InsightsGrid;
