import React, { Component } from 'react';
import styled from '@emotion/styled';
import colors from "styles/colors";
import dimensions from 'styles/dimensions';
import { Inner } from 'styles/structure';
import Content from 'components/_ui/Content';
import { Parallax } from "react-scroll-parallax";

const ContentContainer = styled.section`
  & + * {
    margin-top: 6.4rem;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    & + * {
      margin-top: 8rem;
    }
  }
`;

const ContentInner = styled(Inner)`
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    display: flex;
    
    > * {
      flex: 0 0 50%;
    }
  }
  
  @media(min-width: ${dimensions.desktopUp}px) {
    > * {
      &:first-of-type {
        flex: 0 0 60%;
      }
      
      &:last-of-type {
        flex: 0 0 40%;
      }
    }
  }
`;


const ContentTitle = styled.div`
  position: relative;
  left: -2.6rem;
  
  h2 {
    color: transparent;
    font-size: 12vw;
    font-weight: 600;
    line-height: 1;
    -webkit-text-stroke: 1px ${colors.red600};
    text-stroke: 1px ${colors.red600};
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    left: -4rem;
  }
  
  @media(min-width: ${dimensions.desktopUp}px) {
    h2 {
      font-size: 14.4rem;
    }
  }
`;

const ContentContent = styled.div`
  position: relative;
  z-index: 10;
  margin-top: 4.8rem;
  flex: 1 1 100%;
  
  > * {
    max-width: 62.8rem;
  }
  
  p {
    margin-top: 1.6rem;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    margin-top: 0;
    margin-left: -24rem;
    margin-right: 11.2rem;
    
    p {
      margin-top: 2.4rem;
    }
  }
`;

class SpecialtyContent extends Component {
  render() {
    const { title, content } = this.props;

    return (
      <ContentContainer>
        <ContentInner>
          {title && (
            <Parallax y={[10, -20]}>
              <ContentTitle>
               <h2>{title}</h2>
              </ContentTitle>
            </Parallax>
          )}

          {content && (
            <ContentContent>
              <Content content={content}/>
            </ContentContent>
          )}
        </ContentInner>
      </ContentContainer>
    );
  }
}

export default SpecialtyContent;
