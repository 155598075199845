import React, { Component } from "react";
import styled from "@emotion/styled";
import RichText from 'components/RichText';
/*import colors from "styles/colors";*/
import dimensions from "styles/dimensions";
import { Inner, Wrap } from "styles/structure"
import CleanInput from "components/_ui/CleanInput";
import SectionHeading from "components/_ui/SectionHeading";
import Fade from 'react-reveal/Fade';

const ServicesContainer = styled.section`
  background-color: #000;
  padding: 3.2rem 0 4.8rem;
  color: #fff;
  
  nav {
    margin-bottom: 3.2rem;
    height: 3.2rem;
    overflow: hidden;
    
    > div {
      width: 100%;
      height: 6.4rem;
      overflow-x: scroll;
    }
    
    ul {
      white-space: nowrap;
    }
    
    li {
      display: inline-block;
      margin-left: 2.4rem;
    }  
  }
  
  & + * {
    margin-top: 4.8rem;
  }

  @media(min-width: ${dimensions.desktopUp}px) {    
    nav {
      text-align: center;
    }
    
    & + * {
      margin-top: 14.4rem;
    }
  }
`;

const ServicesTab = styled(CleanInput)`
  color: #fff;
  font-size: 1.6rem;
  padding: 0.8rem;
  opacity: 0.5;
  transition: opacity 0.12s ease-in-out;
  cursor: pointer;
    
  .is-active & {
    opacity: 1;  
  }
`;

const ServicesWrap = styled(Wrap)`
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    display: flex;
    align-items: center;
  }
`;

const ServicesPanels = styled.div`
  position: relative;
  width: 100%;
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    width: 50%;
    padding-right: 4vw;
    flex: 1 1 50%;
  }
`;

const ServicesPanel = styled.div`  
  li {
    position: relative;
    font-size: 1.6rem;
    padding-left: 1.6rem;
    
    &::before {
      content: "";
      position: absolute;
      top: 0.8rem;
      left: 0;
      display: block;
      width: 0.4rem;
      height: 0.4rem;
      background-color: #fff;        
    }
    
    & + * {
      margin-top: 0.4rem;
    }
  }
`;

const ServicesContent = styled.div`
  position: relative;
  margin-top: 2.4rem;
  z-index: 10;
`;

const ServicesMobileImage = styled.div`
  position: relative;
  width: 100%;
  margin-top: -6.4rem;
  z-index: -1;
  
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0));
  }
  
  img {
    max-width: 50vw;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    display: none;
  }
`;

const ServicesImages = styled.div`
  display: none;
  position: relative;
  width: 50%;
  flex: 1 1 50%;
  height: 66.667vh;
  
  div {
    position: absolute;
    width: 100%;
    height: 100%;
    
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0.4;
      transition: opacity 0.12s ease-in-out;
    }
    
    &.is-active {
      z-index: 10;
      
      ::after {
        opacity: 0;
      }
    }
    
    &:nth-of-type(4n) {
      img {
        top: 45%;
      }
    }
    
    &:nth-of-type(4n + 1) {
      img {
        left: 45%;
      }
    }
    
    &:nth-of-type(4n + 2) {
      img {
        top: 55%;
      }
    }
    
    &:nth-of-type(4n + 3) {
      img {
        left: 55%;
      }
    }
  }
  
  img {
    display: block;
    max-width: 80%;
    max-height: 80%;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    display: block;
  }
`;

class SpecialtyServices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: this.props.services[0].title[0].text,
    };
  }

  onClickTab = (name) => {
    this.setState({activeTab: name});
  };

  render() {
    return (
      <ServicesContainer>
        <div>
        </div>

        <nav>
          <div>
            <ul>
              {this.props.services.map((service, i) => (
                <li key={i} className={this.state.activeTab === service.title[0].text && "is-active"}>
                  <ServicesTab
                    as="button"
                    onClick={() => (this.onClickTab(service.title[0].text))}
                  >
                    {service.title[0].text}
                  </ServicesTab>
                </li>
              ))}
            </ul>
          </div>
        </nav>

        <Inner>
          <ServicesWrap>
            <ServicesPanels>
              {this.props.services.map((service, i) => {
                if (this.state.activeTab === service.title[0].text) {
                  return (
                    <Fade bottom distance="2rem">
                      <ServicesPanel key={i}>
                        <Fade bottom distance="1rem">
                          <SectionHeading>{service.title[0].text}</SectionHeading>
                        </Fade>

                        <ServicesContent>
                          <RichText render={service.content}/>

                          {service.image &&
                          <ServicesMobileImage>
                            <img src={service.image.url} alt={service.title[0].text} />
                          </ServicesMobileImage>
                          }
                        </ServicesContent>
                      </ServicesPanel>
                    </Fade>
                  )
                } else {
                  return undefined;
                }
              })}
            </ServicesPanels>

            <ServicesImages>
              {this.props.services.map((service, i) => {
                if (service.image) {
                  if (this.state.activeTab === service.title[0].text) {
                    return (
                      <div className='is-active'>
                        <img
                          key={i}
                          src={service.image.url}
                          alt={service.title[0].text}
                        />
                      </div>
                    )
                  } else {
                    return (
                      <div>
                        <img
                          key={i}
                          src={service.image.url}
                          alt={service.title[0].text}
                        />
                      </div>
                    )
                  }
                } else {
                  return undefined;
                }
              })}

            </ServicesImages>
          </ServicesWrap>
        </Inner>
      </ServicesContainer>
    );
  }
}

export default SpecialtyServices;
