import React, { Component } from 'react';
import styled from '@emotion/styled';
import colors from 'styles/colors';
import dimensions from 'styles/dimensions';
import { Link } from 'gatsby';
import { Rubric } from 'styles/typography';

const CardContainer = styled(Link)`
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;
  height: ${props => props.work ? '100%' : 'calc(100vh - 14.4rem)'};

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    height: auto;
    min-height: 58.4rem;

    &:hover {
      img {
        transform: scale(1.05);
      }
    }
  }
`;

const CardImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  transform: scale(1);
  transform-origin: center;
  transition: transform 0.24s ease-out;
`;

const CardContent = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;

  > * {
    position: relative;
    z-index: 10;
  }
  
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-color: ${colors.red600};
    transition: transform 0.12s ease-in-out;
  }
  
  @supports(mix-blend-mode: multiply) {
    &::before {
      mix-blend-mode: multiply;
      opacity: 1;
    }
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
  
    &::before {
      max-width: 100%;
      min-width: 100%;
    }

    &.is-right {
      left: auto;
      right: 0;
    }
  }
`;

const CardText = styled.div`
  padding: 8.533vw;
  color: #fff;
  isolation: isolate;

  h2 {
    font-weight: 600;
    margin-top: 0.8rem;
  }

  h5 {
    font-weight: 600;
    margin-top: 3.2rem;
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    padding: 6.4rem 5vw;

    h2 {
      margin-top: 1.6rem;
    }

    h5 {
      margin-top: 4.8rem;
    }
  }

  .CaseStudy--work & {
    @media (min-width: ${dimensions.tabletLandscapeUp}px) {
      transform: translateY(-6rem);
    }
  }
`;

const Area = styled(Rubric)`
  display: block;
  color: #fff;
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    font-size: 1.8rem;
  }
`;

class CaseStudy extends Component {
  render() {
    const { uid, image, align, area, name, tagline, variant } = this.props;

    return (
      <CardContainer
        to={`/study/${uid}`}
        className={variant ? `CaseStudy--${variant}` : ""}
        key={this.props.key}
        work={variant === 'work'}
      >
        {image && (
          <CardImage
            src={image.url}
            alt={image.alt}
          />
        )}

        <CardContent className={`${align ? `is-${align}` : ''}`}>
          <CardText>
            {area && <Area>{area.name}</Area>}

            {name && <h5>{name[0].text}</h5>}
            {tagline && <h2>{tagline[0].text}</h2>}
          </CardText>
        </CardContent>
      </CardContainer>
    );
  }
}

export default CaseStudy;
