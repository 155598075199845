import React, { Component } from "react";
import styled from "@emotion/styled";
import colors from "styles/colors";
import dimensions from "styles/dimensions";
import Reveal from "react-reveal/Reveal";
import { drawDown } from "styles/animations";

const SignpostContainer = styled.span`
  display: none;
  position: absolute;
  bottom: 32vh;
  right: 4rem;
  color: ${colors.red600};
  font-size: 1.8rem;
  font-weight: 600;
  transform: rotate(90deg) translateX(-5.6rem);
  transform-origin: right center;
  user-select: none;
  z-index: 10;
  transition: transform 1s cubic-bezier(0.22, 1, 0.36, 1);

  &::after {
    content: "";
    position: absolute;
    left: calc(100% + 2.4rem);
    top: 50%;
    transform: translateY(-50%);
    background-color: ${colors.red600};
    height: 0.3rem;
    width: 40rem;
    transform-origin: left center;
  }

  .signpostIn & {
    transform: rotate(90deg) translateX(0);

    &::after {
      animation-name: ${drawDown};
      animation-duration: 0.3s;
      animation-fill-mode: forwards;
    }
  }

  &.is-xsmall {
    bottom: 0;

    &::after {
      width: 18rem;
    }
  }

  &.is-small {
    bottom: 10.4rem;

    &::after {
      width: 24rem;
    }
  }

  &.is-medium {
    bottom: 24rem;

    &::after {
      width: 31.2rem;
    }
  }

  &.is-large {
    bottom: 32vh;

    &::after {
      width: 40rem;
    }
  }

  &.Signpost-work {
    bottom: 120rem;
  }
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    display: inline-block;
  }
`;

class Signpost extends Component {
  render() {
    return (
      <Reveal effect="signpostIn">
        <SignpostContainer
          className={`is-${this.props.size} Signpost-${this.props.variant}`}
        >
          {this.props.children}
        </SignpostContainer>
      </Reveal>
    );
  }
}

export default Signpost;
