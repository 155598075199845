import React, { Component } from 'react';
import styled from '@emotion/styled';
import dimensions from 'styles/dimensions';
import { Inner } from 'styles/structure';
import Signpost from "components/_ui/Signpost";
import ArrowLink from 'components/_ui/ArrowLink';

const GetInTouchContainer = styled.div`
  margin-top: 16rem;
  padding-bottom: 6.4rem;
  
  h2 {
    width: 70vw;
    max-width: 107.2rem;
    margin-bottom: 1.6rem;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    margin-top: 20.4rem;
    padding: 0 4.4445vw 7.2rem;
  }
`;

class GetInTouch extends Component {
  render() {
    return (
      <GetInTouchContainer>
        <Inner>
          <h2>{this.props.title}</h2>
          <ArrowLink text={this.props.arrowText ? this.props.arrowText : "Get in Touch"} to={this.props.arrowLink ? this.props.arrowLink : "/contact"} />

          {this.props.signpost &&
            <Signpost size="small">{this.props.signpost}</Signpost>
          }
        </Inner>
      </GetInTouchContainer>
    );
  }
}

export default GetInTouch;
